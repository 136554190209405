@import 'styles/tools/_tools.scss';

.logo {
	position:absolute;
	top: $logo-margin;
	left: $logo-margin;
	width: 80%;
	max-width: 100px;
	height: auto;
	fill: $color-1-primary;
	@include mq('small') {
		max-width: 150px;
	}

	&__letters {
		transition: fill .9s, stroke .9s;
		.active & {
			fill: $color-4;
			stroke: $color-4;
		}
	}
}
