@import 'styles/tools/_tools.scss';

.navigation {
	padding: 2em 1em 1em;
	display: block;
	text-align: right;

	&__item {
		font-family: 'Quicksand', 'Arial Rounded MT Bold', sans-serif;
		font-weight: bold;
		font-size: .8rem;
		text-decoration: none;
		color: $color-1-primary;
		display: inline-block;
		position: relative;
		overflow: hidden;
		padding: .5em .5em;
		border-bottom: 3px solid transparent;
		border-radius: 3px;
		transition: color .7s ease, transform .1s ease, margin-bottom .1s ease;

		@include mq('xsmall') {
			font-size: 1.2rem;
		}
		@include mq('small') {
			font-size: 1.4rem;
		}

		// The Border:
		&:after {
			content:"";
			display: inline-block;
			width: 100%;
			height: 1px;
			background-color: transparent;
			position: absolute;
			bottom: .4em;
			left: 0;
			transition: background-color .7s ease, width .5s ease, left .5s ease;
			@include mq('xsmall') {
				height: 2px;
			}
			@include mq('small') {
				height: 3px;
			}
		}

		&:active {
			transform: scale3d(.95, .95, .95);
			margin-bottom: -4px;
		}

		&.active {
			color: $color-3;
			&:after {
				background-color: $color-3;
				width: calc(100% - 1em);
				left: .5em;
			}
		}
	}
}