a {
	text-decoration: none;
	outline: none;
	-webkit-tap-highlight-color: transparent;

	article & {
		color: $color-3;
		position: relative;
		font-weight: bold;
		@include mq('medium') {
			display: inline-block;
		}

		&:after {
			@include mq('medium') {
				content:" ";
				display: inline-block;
				width: 110%;
				height: 2px;
				background-color: transparent;
				position: absolute;
				bottom: 0;
				left: -5%;
				transition: background-color .7s ease, width .5s ease, left .5s ease;
			}
		}

		&:hover {
			&:after {
				@include mq('medium') {
					background-color: $color-3;
					width: 100%;
					left: 0;
				}
			}
		}
		
	}
}