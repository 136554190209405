@import './tools/tools';
// Default styles

html {
	font-family:sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
	background: $color-5;
	color: $color-1-primary;
	overflow-y: scroll; // prevent the page from "jumping" when navigating. 
  /*
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  */
}