@import './tools/tools';

.page-header {
	position: relative;
	z-index: 1;
	min-width: $size-base;
	max-width: $content-size + ($logo-margin * 2);
	margin: 0 auto;
}

.main {
	margin-top: 150px;
	min-width: $size-base;

	@include mq('small') {
		margin-top: 15vh;
	}
}

article {
	max-width: $content-size;
	margin-right: auto;
	margin-left: auto;
	padding: 0 25px;

	@include mq('small') {
		//text-align: justify;
	}
}