// Screen-sizes: 
$size-base: 320px;
$size-xsmall: 360px;
$size-small: 576px;
$size-medium: 768px;
$size-large: 992px;
$size-xlarge: 1200px;
$size-xl-overflow: $size-xlarge + 60px;

// Size adjustments:
$content-size: 650px;
$logo-margin: 25px;

// Media Queries Breakpoints:
$breakpoints: (
   'xsmall':   $size-xsmall,
   'small':    $size-small,
   'medium':   $size-medium,
   'large':    $size-large,
   'xlarge':   $size-xlarge,
   'xl-overflow':    $size-xl-overflow
);

// Media Queries Mixin default:
@mixin mq($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}

// Media Queries Mixin limit:
@mixin mq-limit($width-start, $width-end, $type-start: min, $type-end: max) {
	@if map_has_key($breakpoints, $width-start) {
		$width-start: map_get($breakpoints, $width-start);
		$width-end: map_get($breakpoints, $width-end);

		@media only screen and (#{$type-start}-width: $width-start) and (#{$type-end}-width: $width-end){
			@content;
		}
	}
}

// Media Querie example use:
//
//  .foo {
//      padding: 20px;
//
//      @include mq('small') {
//          padding-top: 40px;
//      }
//  }
//
//  .foo {
//      padding: 20px;
//
//      @include mq('medium', max) {
//          padding-top: 10px;
//      }
//	}
