
.transition-group {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 0;
}

.transition-item {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

.transition-appear, 
.transition-enter {
	opacity: 0;
	z-index: 1;
	top: 100px;
}

.transition-appear-active, 
.transition-enter.transition-enter-active {
	opacity: 1;
	top: 0;
	transition: opacity 300ms linear 150ms, top 250ms ease-out 150ms;
}

.transition-exit {
	opacity: 1;
}
.transition-exit.transition-exit-active {
	opacity: 0;
	transition: opacity 150ms linear;
}