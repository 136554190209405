@import './tools/tools';

// Eleminate Microsoft Internet Explorer version 10 & 11:

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	body {
		display: none;
	}
	head {
		display: block;
	}
	head:after {
		display: block;
		content: "Microsoft Internet Explorer is outdated, insecure and unsupported!";
		color: $color-3;
		font-size: 1.8rem;
		font-family: 'Quicksand', 'Arial Rounded MT Bold', sans-serif;
		font-weight: bold;
		text-align: center;
		line-height: 1.1;
		margin-bottom: 1em;
	}
	html {
		background-color: $color-5;
		padding: 5em;
	}
	html:after {
		display: block;
		content: "Please use Microsoft Edge 16+, Firefox, Chrome, or any other modern browser to enable this website";
		color: $color-1-primary;
		font-size: 1.15rem;
		text-align: center;
		font-family: 'Quicksand', 'Arial Rounded MT Bold', sans-serif;
		line-height: 1.4;
	}
}



// Eleminate Microsoft Edge version 12-15:

@supports (-ms-ime-align:auto) and (not (display: grid)) {
	body {
		display: none;
	}
	head {
		display: block;
	}
	head:after {
		display: block;
		content: "Your Edge browser is outdated!";
		color: $color-3;
		font-size: 1.8rem;
		font-family: 'Quicksand', 'Arial Rounded MT Bold', sans-serif;
		font-weight: bold;
		text-align: center;
		line-height: 1.1;
		margin-bottom: 1em;
	}
	html {
		background-color: $color-5;
		padding: 5em;
	}
	html:after {
		display: block;
		content: "Please update to the latest version of Microsoft Edge (at least version 16) or any other modern browser, to enable this website";
		color: $color-1-primary;
		font-size: 1.15rem;
		text-align: center;
		font-family: 'Quicksand', 'Arial Rounded MT Bold', sans-serif;
		line-height: 1.4;
	}
}