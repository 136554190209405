// Blue Theme: https://colors.muz.li/palette/0775c6/d3d4ce/181d33/05267b/76bee0
$blue-black: 				#181d33;
$blue-dark: 				#05267b;
$blue-medium: 			#0775c6;
$blue-light: 				#76bee0;
$blue-gray: 				#d3d4ce;

// Curent color mapping:
$color-1-primary: 	$blue-black;
$color-2: 					$blue-dark;
$color-3: 					$blue-medium;
$color-4: 					$blue-light;
$color-5: 					$blue-gray;