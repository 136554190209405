@import './tools/tools';

h1 {
	font-size: 2.2rem;
	margin:0;
	font-family: 'Quicksand', 'Arial Rounded MT Bold', sans-serif;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	text-align: right;
	transition: font-size .2s;

	@include mq('xsmall') {
		font-size: 2.7rem;
	}
	@include mq('small') {
		font-size: 4.3rem;
	}
	@include mq('medium') {
		font-size: 5.5rem;
	}
}

.sub-title {
	font-size: 1.5rem;
	font-family: 'Playball', 'Brush Script MT', serif;
	line-height: 1.5;
	margin: 2rem 0 .3em 0;
	color: $color-3;
	transition: font-size .2s;
	@include mq('xsmall') {
	}
	@include mq('small') {
	}
	@include mq('medium') {
	}
}

.sticky-sub-title {
	font-size: 1.13rem;
	margin:0;
	font-family: 'Playball', 'Brush Script MT', serif;

	line-height: 1.2;
	text-align: right;
	margin-bottom: 1em;
	margin: -.72em 0 0;
	padding-right: .2em;

	text-shadow: 0px -2px 0px $color-5;
	transition: font-size .2s;
	color: $color-3;
	@include mq('xsmall') {
		font-size: 1.37rem;
		margin-bottom: 1.1em;
	}
	@include mq('small') {
		font-size: 2.17rem;
		margin-bottom: 1.25em;
	}
	@include mq('medium') {
		font-size: 2.8rem;
	}
}

.text {
	font-family: 'Quicksand', sans-serif;
	font-weight: normal;
	line-height: 1.6;
	font-size: 1.2rem;
	margin: .3em 0 1em 0;
}

article p:last-of-type {
	&:after {
		content:"";
		display: inline-block;
		border-bottom: 1px solid $color-1-primary;
		margin-bottom: -3px;
		margin-left: 2px;
		width: 1rem;
		line-height: inherit;
		font-size: inherit;

		@keyframes blink {
			from {opacity: 1.0;}
			to {opacity: 0.0;}
		}
		animation-name: blink;
		animation-duration: .7s;
		animation-iteration-count:infinite;
		animation-timing-function:ease-in-out;
		animation-direction: alternate;

	}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

address {
	font-family: 'Quicksand', sans-serif;
	font-size: 85%;
	font-style: normal;
	font-weight: bold;
	line-height: 1.3;
	border: 4px solid $color-1-primary;
	border-radius: 50%;
	padding: 2.5em 2em 2.5em 3em;
	color: $color-1-primary;
	float: right;
	margin-bottom: 25px;
}